code.highlighter-rouge { background-color: #222222; padding: 2px; color: #f0e32f ; font-weight: 900; }
.highlight pre { background-color: #222222; padding: 10px; white-space: pre-wrap; }
.highlight .hll { background-color: #222222 }
.highlight .c { color: #c57991 } /* Comment */
.highlight .err { color: #cccccc; border: 1px solid #FF0000 } /* Error */
.highlight .g { color: #cccccc } /* Generic */
.highlight .k { color: #cdcd00 } /* Keyword */
.highlight .l { color: #cccccc } /* Literal */
.highlight .n { color: #cccccc } /* Name */
.highlight .o { color: #3399cc } /* Operator */
.highlight .x { color: #cccccc } /* Other */
.highlight .p { color: #cccccc } /* Punctuation */
.highlight .cm { color: #c57991 } /* Comment.Multiline */
.highlight .cp { color: #c57991 } /* Comment.Preproc */
.highlight .c1 { color: #c57991 } /* Comment.Single */
.highlight .cs { color: #cd0000; font-weight: bold } /* Comment.Special */
.highlight .gd { color: #cd0000 } /* Generic.Deleted */
.highlight .ge { color: #cccccc; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #FF0000 } /* Generic.Error */
.highlight .gh { color: #c57991; font-weight: bold } /* Generic.Heading */
.highlight .gi { color: #00cd00 } /* Generic.Inserted */
.highlight .go { color: #808080 } /* Generic.Output */
.highlight .gp { color: #c57991; font-weight: bold } /* Generic.Prompt */
.highlight .gs { color: #cccccc; font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
.highlight .gt { color: #0040D0 } /* Generic.Traceback */
.highlight .kc { color: #cdcd00 } /* Keyword.Constant */
.highlight .kd { color: #00cd00 } /* Keyword.Declaration */
.highlight .kn { color: #cd00cd } /* Keyword.Namespace */
.highlight .kp { color: #cdcd00 } /* Keyword.Pseudo */
.highlight .kr { color: #cdcd00 } /* Keyword.Reserved */
.highlight .kt { color: #00cd00 } /* Keyword.Type */
.highlight .ld { color: #cccccc } /* Literal.Date */
.highlight .m { color: #cd00cd } /* Literal.Number */
.highlight .s { color: #cd0000 } /* Literal.String */
.highlight .na { color: #cccccc } /* Name.Attribute */
.highlight .nb { color: #cd00cd } /* Name.Builtin */
.highlight .nc { color: #00cdcd } /* Name.Class */
.highlight .no { color: #cccccc } /* Name.Constant */
.highlight .nd { color: #cccccc } /* Name.Decorator */
.highlight .ni { color: #cccccc } /* Name.Entity */
.highlight .ne { color: #666699; font-weight: bold } /* Name.Exception */
.highlight .nf { color: #cccccc } /* Name.Function */
.highlight .nl { color: #cccccc } /* Name.Label */
.highlight .nn { color: #cccccc } /* Name.Namespace */
.highlight .nx { color: #cccccc } /* Name.Other */
.highlight .py { color: #cccccc } /* Name.Property */
.highlight .nt { color: #cccccc } /* Name.Tag */
.highlight .nv { color: #00cdcd } /* Name.Variable */
.highlight .ow { color: #cdcd00 } /* Operator.Word */
.highlight .w { color: #cccccc } /* Text.Whitespace */
.highlight .mf { color: #cd00cd } /* Literal.Number.Float */
.highlight .mh { color: #cd00cd } /* Literal.Number.Hex */
.highlight .mi { color: #cd00cd } /* Literal.Number.Integer */
.highlight .mo { color: #cd00cd } /* Literal.Number.Oct */
.highlight .sb { color: #cd0000 } /* Literal.String.Backtick */
.highlight .sc { color: #cd0000 } /* Literal.String.Char */
.highlight .sd { color: #cd0000 } /* Literal.String.Doc */
.highlight .s2 { color: #cd0000 } /* Literal.String.Double */
.highlight .se { color: #cd0000 } /* Literal.String.Escape */
.highlight .sh { color: #cd0000 } /* Literal.String.Heredoc */
.highlight .si { color: #cd0000 } /* Literal.String.Interpol */
.highlight .sx { color: #cd0000 } /* Literal.String.Other */
.highlight .sr { color: #cd0000 } /* Literal.String.Regex */
.highlight .s1 { color: #cd0000 } /* Literal.String.Single */
.highlight .ss { color: #cd0000 } /* Literal.String.Symbol */
.highlight .bp { color: #cd00cd } /* Name.Builtin.Pseudo */
.highlight .vc { color: #00cdcd } /* Name.Variable.Class */
.highlight .vg { color: #00cdcd } /* Name.Variable.Global */
.highlight .vi { color: #00cdcd } /* Name.Variable.Instance */
.highlight .il { color: #cd00cd } /* Literal.Number.Integer.Long */
