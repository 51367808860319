html {
  font-size: 62.5%;
}

body {
  font-size: 1.5em;
  line-height: 1.6;
  font-family: 'Space Mono', 'Courier New', Courier, monospace;
  color: #222;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  text-align: justify;

  font-size: 14px;

  @media (min-width: 768px) {
    width: 650px;
  }

  @media (min-width: 992px) {
    width: 750px;
  }

  @media (min-width: 1200px) {
    width: 950px;
  }
}

#programming-languages + ul,
#markup-languages + ul,
#pentesting + ul,
#tools--software + ul,
#ibm-specific + ul,
#operating-systems + ul {
  @media (min-width: 768px) {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  @media (min-width: 992px) {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
}