@import "variables";

body {
  background-color: $black;
  color: $white;
}

h2 {
  color: $green;
  font-size: 36px;
  font-weight: 500;

  &:before {
    content: "~$";
    padding-right: 5px;
  }
}

h3 {
  color: $orange;

  font-size: 26px;
  line-height: 28.6px;
  font-weight: 500;

  a {
    color: $orange;
  }

  &:before {
    content: ">";
    padding-right: 5px;
    color: $green;
  }
}

h4 {
  font-size: 16px;
  line-height: 18.0px;
  font-weight: 300;
  &:before {
    content: "--";
    padding-right: 5px;
    color: $green;
  }
}

a {
  color: $green;
  text-decoration: underline;
}

a:hover,a:focus {
  color: #333;
  text-decoration: none;
  background: #ecf0f1;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

#footer {
  margin-top: 100px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 60%;
  color:#ecf0f1;
  z-index: 9;
  top: 98%;
}

hr {
  border-top: 1px dashed $green;
}

em{
  color:#7bf3ad;
}

.imgbox {
            display: grid;
            height: 70%;
}

.center-fit {
            max-width: 70%;
            max-height: 70vh;
            margin: auto;
}

.iframe-loader {
  background:url(/assets/images/video-loading-bar.gif) center center no-repeat;
}